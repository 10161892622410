import { Constants } from '@crossbordersolutions/pro-utils';

import { EntityFromUpload, Level, Step } from '../models';

export const { UsaGroupUuid, WorldGroupUuid, UsaGroupId, WorldGroupId, UsaJurisdictionId } = Constants;

export const defaultGroupNames = {
  [WorldGroupUuid]: 'Worldwide Consolidated',
  [UsaGroupUuid]: 'US Consolidated'
};

export const FinalizedContainerStatusesByName = {
  finalized: 'FINALIZED',
  finalizedBeforeEFE: 'FINALIZED_BEFORE_EFE',
  finalizedAfterEFE: 'FINALIZED_AFTER_EFE'
};
export const FinalizedContainerStatuses = Object.values(FinalizedContainerStatusesByName);

export const federalSteps = [
  'rtp',
  'ptbi',
  'permanent',
  'temporary',
  'nol',
  'credits',
  'tax-effected',
  'deferred',
  'accounts'
] as const;

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
] as const;

export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
] as const;

export const getFederalSteps = () =>
  ['rtp', 'ptbi', 'permanent', 'temporary', 'nol', 'credits', 'tax-effected', 'deferred', 'accounts'] as const;

export const federalStepsByName: Record<string, typeof federalSteps[number]> = {
  RTP: 'rtp',
  PTBI: 'ptbi',
  PERMANENT: 'permanent',
  TEMPORARY: 'temporary',
  NOL: 'nol',
  CREDITS: 'credits',
  TAX_EFFECTED: 'tax-effected',
  DEFERRED: 'deferred',
  ACCOUNTS: 'accounts'
};

export const stateSteps = [
  'rtp',
  'apportionment',
  'modifications',
  'nol',
  'credits',
  'tax-effected',
  'deferred',
  'accounts'
] as const;

export const stateStepsByName: Record<string, typeof stateSteps[number]> = {
  RTP: 'rtp',
  APPORTIONMENT: 'apportionment',
  MODIFICATIONS: 'modifications',
  NOL: 'nol',
  CREDITS: 'credits',
  TAX_EFFECTED: 'tax-effected',
  DEFERRED: 'deferred',
  ACCOUNTS: 'accounts'
};

export const getStateSteps = () =>
  ['rtp', 'apportionment', 'modifications', 'nol', 'credits', 'tax-effected', 'deferred', 'accounts'] as const;

export const discreteSteps = ['adjustment'] as const;

export const LEVELS: Record<string, Level> = {
  FEDERAL: 'federal',
  STATE: 'state',
  DISCRETE: 'discrete'
} as const;

export const COLUMNS = {
  balanceSheetOnlyAdjustment: 'balanceSheetOnlyAdjustment',
  beginningBalance: 'beginningBalance',
  deferredOnlyAdjustment: 'deferredOnlyAdjustment',
  deferredGrossEndingBalance: 'deferredGrossEndingBalance',
  generatedAmount: 'generatedAmount',
  note: 'note',
  remaining: 'remaining',
  rtp: 'rtp',
  usedAmount: 'usedAmount',
  difference: 'difference',
  endingBalance: 'endingBalance',
  m1Adjustment: 'm1',
  manualEndingBalance: 'manualEndingBalance',
  amount: 'amount',
  disallowedPct: 'disallowedPct',
  taxProvision: 'taxProvision',
  taxReturn: 'taxReturn',
  beginningPayments: 'beginningPayments',
  endingPayments: 'endingPayments',
  bookBalance: 'bookBalance',
  taxBalance: 'taxBalance',
  state: 'state'
};

export const ATTRIBUTE_NAMES = {
  priorYearFedTaxRate: 'Prior Year Federal Tax Rate',
  priorYearStateTaxRate: 'Prior Year State Tax Rate',
  adjustments: 'Adjustments',
  preTaxBookIncomeLoss: 'Pre-Tax Book Income / (Loss)'
};

export const FED_TEMP_BALANCE_SHEET_LEVEL = LEVELS.FEDERAL;
export const FED_TEMP_BALANCE_SHEET_STEP = 'temporary.balanceSheet';
export const FED_TEMP_BALANCE_SHEET_LEVEL_AND_STEP = `${FED_TEMP_BALANCE_SHEET_LEVEL}.${FED_TEMP_BALANCE_SHEET_STEP}`;

export const FED_TEMP_INCOME_STATEMENT_STEP = 'temporary.incomeStatement';
export const FED_TEMP_INCOME_STATEMENT_LEVEL_AND_STEP = `${LEVELS.FEDERAL}.${FED_TEMP_INCOME_STATEMENT_STEP}`;

export const FEDERAL_SUB_STEPS = [FED_TEMP_BALANCE_SHEET_STEP, FED_TEMP_INCOME_STATEMENT_STEP] as const;
export const STATE_SUB_STEPS = ['modifications.permanent', 'modifications.temporary', 'apportionment-flagged'] as const;

export const CATEGORIZABLE_SECTIONS_BY_TAB: Record<Level, Partial<Record<Step, Set<string>>>> = {
  federal: {
    deferred: new Set(['federal.temporary.incomeStatement', 'federal.temporary.balanceSheet']),
    permanent: new Set(['federal.permanent']),
    rtp: new Set([
      'federal.permanent',
      'federal.temporary.incomeStatement',
      'federal.temporary.balanceSheet',
      'federal.tax-effected'
    ]),
    'tax-effected': new Set(['federal.tax-effected']),
    temporary: new Set(['federal.temporary.balanceSheet', 'federal.temporary.incomeStatement']),
    'temporary.incomeStatement': new Set(['federal.temporary.incomeStatement', 'federal.temporary.balanceSheet']),
    'temporary.balanceSheet': new Set(['federal.temporary.incomeStatement', 'federal.temporary.balanceSheet'])
  },
  state: {
    deferred: new Set([
      'federal.temporary.incomeStatement',
      'federal.temporary.balanceSheet',
      'state.modifications.temporary',
      'state.modifications.permanent'
    ]),
    rtp: new Set(['state.modifications.permanent', 'state.modifications.temporary', 'state.tax-effected']),
    modifications: new Set(['state.modifications.temporary', 'state.modifications.permanent']),
    'modifications.permanent': new Set(['state.modifications.permanent']),
    'modifications.temporary': new Set(['state.modifications.temporary']),
    'tax-effected': new Set(['state.tax-effected'])
  },
  discrete: {}
};

export const WalkMeConstants = {
  staging: `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/ea99c6174fa949ed89103c49ab1d2ee0/test/walkme_ea99c6174fa949ed89103c49ab1d2ee0_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`,
  production: `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/ea99c6174fa949ed89103c49ab1d2ee0/walkme_ea99c6174fa949ed89103c49ab1d2ee0_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`
};

export const REFRESH_URL = 'v1/refresh';

export const AUTH_REFRESH_TIME_WINDOW_MILLISECONDS = 20 * 60 * 1000;
export const AUTH_REFRESH_CHRON_KEY = 'AUTH_REFRESH_CHRON_TOKEN';

export const UPLOAD_REVIEW_TABS = [
  { key: 'currencies', label: 'Currencies' },
  { key: 'jurisdictions', label: 'Jurisdictions' },
  { key: 'states', label: 'States / Cities' },
  { key: 'ranges', label: 'Account Ranges' },
  { key: 'tax-sensitive', label: 'Tax Sensitive Acc.' },
  { key: 'confirm', label: 'Confirm & Import' }
];

export const UPLOAD_REVIEW_TABS_ERRORS = [
  'Please assign all entities a Currency.',
  'Please assign all entities a Jurisdiction.',
  'Please assign all relevant entities a State or City.',
  'Please assign all accounts to a range.'
];

export const UPLOAD_REVIEW_ID: Record<string, keyof EntityFromUpload> = {
  currencies: 'currencyId',
  jurisdictions: 'jurisdictionId',
  states: 'subJurisdictionIds'
};

export const NULL_UUID = '00000000-0000-0000-0000-000000000000';
export const FEDERAL_UUID = NULL_UUID;

export const SUB_JUR_IDS_FOR_TESTS = [
  '6fa12ca0-d0af-11eb-8ddc-d190cd5f813e',
  '024aa540-d0b0-11eb-8ddc-d190cd5f813e',
  '0310db70-d0b0-11eb-8ddc-d190cd5f813e',
  '03bf6af0-d0b0-11eb-8ddc-d190cd5f813e',
  '04311880-d0b0-11eb-8ddc-d190cd5f813e',
  '05136230-d0b0-11eb-8ddc-d190cd5f813e'
];

export const CANCELED_HTTP_ERROR_MESSAGE = 'canceled';

export const CURRENCY_ISO_TO_SYMBOL: Record<string, string> = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$'
};

export const USD_CURRENCY_ID = 150;

export const TABS_WITH_IMPORT_BUTTON_AT_TOP: {
  federal: Partial<Record<Step, boolean>>;
  state: Partial<Record<Step, boolean>>;
} = {
  federal: {
    rtp: false,
    deferred: false
  },
  state: {
    rtp: false,
    deferred: false
  }
};

export const DASHBOARD_CARD_TYPES = {
  effectiveTaxRate: 'effectiveTaxRate',
  totalProvision: 'totalProvision',
  deferredTaxAsset: 'deferredTaxAsset',
  preTaxBookIncome: 'preTaxBookIncome',
  jurisdictions: 'jurisdictions',
  entityStatus: 'entityStatus'
} as const;

export const REPORT_STATUS = {
  ready: 1,
  inProgress: 2,
  failed: 3
};

export const { Reports, UiTabs } = Constants;

export const AuditTrailSearchFocus = {
  all: 'all',
  tabs: 'tabs',
  accountNumbers: 'accountNumbers',
  adjustments: 'adjustments',
  categories: 'categories'
} as const;

export const StepShortNames = {
  [UiTabs.federal.rtp]: 'RTP',
  [UiTabs.federal.ptbi]: 'PTBI',
  [UiTabs.federal.permanent]: 'Perm',
  [UiTabs.federal.temporaryIncomeStatement]: 'Temp',
  [UiTabs.federal.temporaryBalanceSheet]: 'Temp',
  [UiTabs.state.apportionment]: 'Apportionment',
  [UiTabs.state.modificationsPermanent]: 'Mods',
  [UiTabs.state.modificationsTemporary]: 'Mods',
  [UiTabs.federal.nol]: 'NOL',
  [UiTabs.federal.credits]: 'Credits',
  [UiTabs.federal.taxEffected]: 'Tax Eff',
  [UiTabs.federal.deferred]: 'Deferred',
  [UiTabs.federal.accounts]: 'Accounts'
};

// Determined by the principle DB, container table, 'name' column
export const ContainerNameMaxLength = 75;

export const reservedNames = [
  'pre-tax book income / (loss)',
  'adjustments',
  'state tax deduction',
  'net operating loss',
  'prior year federal tax rate',
  'federal taxable income/(loss) before state tax and nol',
  'pre-apportioned net operating loss',
  'pre-apportioned state taxable income',
  'apportionment factor',
  'post-apportioned state taxable income/(nol) before nol',
  'post-apportioned net operating loss',
  'post-apportioned state taxable income/(nol)',
  'prior year state tax rate',
  'state tax expense/(benefit) before credits',
  'state tax expense/(benefit) after credits'
];
