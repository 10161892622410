import { useState, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TableVirtuoso, VirtuosoHandle } from 'react-virtuoso';

import { SlideOut } from '@xbs/xbs-common-ui';

import HeaderContent from './HeaderContent';
import RowContent from './RowContent';
import { useStyles } from './Styles';
import { VirtuosoTableComponents, notesColumn } from './utils';

import { useContainers } from '../../hooks';
import { useCategorizableSections } from '../../hooks/useCategorizableSections';
import { CategoryFromApi, Level, Step } from '../../models';
import { selectIsEntityDetailsGroupedViewToggled } from '../../selectors';
import { selectCategories } from '../../selectors/categories';
import { groupEntityDetailsCategoryRows } from '../../utils/table';
import { Column, TableProps, Row } from '../Table/Table.proptype';
import { ASC, categorizeRows, DESC, filterAndSort } from '../Table/utils';
import { CommentsPanel } from '../TableWithComment/components';
import { TableWithCommentProps } from '../TableWithComment/TableWithComment.proptype';

interface VirtualTableProps {
  rows: Row[];
  columns: Column[];
  failedCells: TableProps['failedCells'];
  onCellOrCommentBlur: TableProps['onCellOrCommentBlur'];
  includeNotesColumn: boolean;
  step: Step;
  level: Level;
  hideActionsMenu?: TableWithCommentProps['hideActionsMenu'];
  expanded?: TableWithCommentProps['expanded'];
  toggleCategoryDrawer?: TableWithCommentProps['toggleCategoryDrawer'];
  toggleEditCategoryDrawer?: TableWithCommentProps['toggleEditCategoryDrawer'];
  handleOnRowDelete?: TableWithCommentProps['handleOnRowDelete'];
  renderOpts?: any;
  onExpand?: TableWithCommentProps['onExpand'];
}

const VirtualTable = (props: VirtualTableProps) => {
  const {
    columns,
    rows,
    failedCells,
    onCellOrCommentBlur,
    expanded,
    toggleCategoryDrawer,
    toggleEditCategoryDrawer,
    handleOnRowDelete,
    renderOpts,
    onExpand,
    level,
    step,
    includeNotesColumn,
    hideActionsMenu = false
  } = props;
  const scrollTop = useRef(0);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [expandedRow, setExpandedRow] = useState(expanded);
  const { t } = useTranslation();
  const { categories } = useSelector(selectCategories);
  const { currentContainer } = useContainers();
  const stepLevel = `${level}.${step}`;
  const categorizableSections = useCategorizableSections(level, step);
  const isCategorizableTab = Boolean(categorizableSections);
  const isGroupedViewToggleOn = useSelector(selectIsEntityDetailsGroupedViewToggled);
  const shouldGroupCategories = isGroupedViewToggleOn && isCategorizableTab;
  const classes: Record<string, string> = useStyles({ isGroupedViewToggleOn });
  const displayCategoryActions = false;
  const displayGroupActions = false;
  const isUserReadOnly = false;
  const hasNewRow = false;
  const titleHeight = 120;
  const [sort, setSort] = useState<{ column: Column | null; dir: string }>({ column: null, dir: DESC });
  const [filters, setFilters] = useState(new Map());
  const [displayedRows, setDisplayedRows] = useState<Row[]>(rows);
  const [displayedColumns, setDisplayedColumns] = useState<Column[]>(columns);
  const [displayedCategoriesByAccountKey, setDisplayedCategoriesByAccountKey] = useState<
    Record<any, CategoryFromApi[]>
  >({});
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  useEffect(() => {
    let rowsStrategy = rows;
    const notesColumnLayout = notesColumn({
      classes,
      handleOnRowDelete,
      onExpand,
      setExpandedRow,
      toggleEditCategoryDrawer,
      toggleCategoryDrawer,
      t,
      hideActionsMenu,
      isUserReadOnly,
      hasNewRow,
      displayCategoryActions,
      displayGroupActions,
      stepLevel
    });

    let columnsStrategy = [...columns];
    if (includeNotesColumn) {
      columnsStrategy.push(notesColumnLayout);
    }

    const shouldDisplayCategoryDot = columnsStrategy && categories;
    const categoriesByAccountKey: Record<any, CategoryFromApi[]> = {};

    if (shouldDisplayCategoryDot) {
      const filteredCategories = categories.filter((category) => {
        return categorizableSections?.has(category.accounts[0].stepName);
      });

      filteredCategories
        .map((category) => category.accounts.map((account) => ({ ...account, category })))
        .flat()
        .forEach((accountData) => {
          const standardizedAttributeName = accountData.attributeName.toLowerCase().trim();
          categoriesByAccountKey[standardizedAttributeName] = categoriesByAccountKey[standardizedAttributeName] ?? [];

          categoriesByAccountKey[standardizedAttributeName].push(accountData.category);

          return categoriesByAccountKey;
        });

      rowsStrategy = categorizeRows(rows, categoriesByAccountKey, currentContainer);

      if (shouldGroupCategories) {
        rowsStrategy = groupEntityDetailsCategoryRows(rows, columns);
      }

      /* Avoid rendering states selector on category total rows */
      columnsStrategy = columnsStrategy.map((column) => {
        if (column.field === 'state' && column.renderCell) {
          const originalRenderCell = column.renderCell as (...args: any[]) => any;
          return {
            ...column,
            renderCell: (row, value) => {
              if (row.isTotal && row.category) {
                return <div />;
              }

              return originalRenderCell(row, value);
            }
          };
        }

        return column;
      });
    }

    setDisplayedCategoriesByAccountKey(categoriesByAccountKey);
    setDisplayedColumns(columnsStrategy);
    setDisplayedRows(rowsStrategy);
  }, [
    categories,
    categorizableSections,
    currentContainer,
    classes,
    columns,
    displayCategoryActions,
    displayGroupActions,
    handleOnRowDelete,
    hasNewRow,
    hideActionsMenu,
    includeNotesColumn,
    isUserReadOnly,
    onExpand,
    rows,
    shouldGroupCategories,
    stepLevel,
    t,
    toggleCategoryDrawer,
    toggleEditCategoryDrawer
  ]);

  useEffect(() => {
    setExpandedRow(expanded);
  }, [expanded]);

  useEffect(() => {
    if (virtuosoRef?.current) {
      virtuosoRef.current.scrollTo({ top: scrollTop.current });
    }
  }, [onCellOrCommentBlur]);

  function handleFilterChange(column: Column, searched: string | number) {
    if (column?.field) {
      if (searched) {
        filters.set(column.field, { searched, column });
      } else {
        filters.delete(column.field);
      }

      const newFilters = new Map(filters.entries());
      setFilters(newFilters);
      setIsFiltered(true);
    }
  }

  function handleSortClick(column: Column | null) {
    const newSort =
      sort.column === null
        ? { column, dir: ASC }
        : sort.column === column && sort.dir === ASC
        ? { column, dir: DESC }
        : sort.column === column && sort.dir === DESC
        ? { column, dir: ASC }
        : sort.column !== column && sort.column !== null
        ? { column, dir: ASC }
        : { column: null, dir: ASC };
    setIsFiltered(true);
    setSort(newSort);
  }

  useEffect(() => {
    if (isFiltered) {
      const sortedRows = [];
      let sortValues: Row[] = [];

      for (const row of rows) {
        if (row.sectionHeader || row.isTotal) {
          sortValues = filterAndSort(sortValues, filters, sort);
          sortedRows.push(...sortValues, row);
          sortValues = [];
        } else {
          sortValues.push(row);
        }
      }

      if (sortValues.length > 0) {
        sortedRows.push(...filterAndSort(sortValues, filters, sort));
      }

      setDisplayedRows(sortedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort, rows]);

  return (
    <>
      <TableVirtuoso
        ref={virtuosoRef}
        overscan={400}
        context={{ classes }}
        data={displayedRows}
        fixedHeaderContent={() => (
          <HeaderContent
            columns={displayedColumns}
            classes={classes}
            sort={sort}
            filters={filters}
            onFilterChange={handleFilterChange}
            onSortClick={handleSortClick}
          />
        )}
        itemContent={(index: number, row: Row) => (
          <RowContent
            index={index}
            row={row}
            renderOpts={renderOpts}
            columns={displayedColumns}
            categoriesByAccountKey={displayedCategoriesByAccountKey}
            currentContainer={currentContainer}
            classes={classes}
            failedCells={failedCells}
            onCellOrCommentBlur={onCellOrCommentBlur}
          />
        )}
        components={VirtuosoTableComponents}
        onScroll={(e) => {
          scrollTop.current = (e.target as HTMLElement).scrollTop;
        }}
      />
      <SlideOut.Drawer
        isDividerHidden
        isFloating={false}
        subtitle=""
        title={t('Note')}
        titleHeight={titleHeight}
        isOpen={Boolean(expandedRow)}
        onClose={() => {
          if (onExpand) {
            onExpand(null);
          } else {
            setExpandedRow(null);
          }
        }}
      >
        <CommentsPanel
          isDisabled={hideActionsMenu || isUserReadOnly}
          // @ts-expect-error TODO - we need to figure out if expandedRow name can really be undefined
          row={expandedRow}
          hasNewRow={hasNewRow}
          rowComments={expandedRow?.comments ?? expandedRow?.note}
          onCellOrCommentBlur={onCellOrCommentBlur}
        />
        {/* Remove `expandedRow?.comments` once we hook all table to the api */}
      </SlideOut.Drawer>
    </>
  );
};

export default VirtualTable;
