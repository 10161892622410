import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import { saveAs } from 'file-saver';

import { WorldGroupUuid, defaultGroupNames } from '../../../constants';
import { Container } from '../../../models';
import { selectEntitiesMapById } from '../../../selectors';
import { selectCustomGroups } from '../../../selectors/customGroups';
import HTTPService from '../../../services/http';
import b64toBlob from '../../../utils/b64-to-blob';

interface Props {
  spreadsheetReportsDemo: boolean;
  reportType: string;
  reportSourceId: string;
  currencyISO: string;
  container?: Container;
}

const DownloadReportsButton = ({
  spreadsheetReportsDemo,
  reportType,
  reportSourceId,
  currencyISO,
  container
}: Props) => {
  const { t } = useTranslation();
  const entities = useSelector(selectEntitiesMapById);
  const { customGroups } = useSelector(selectCustomGroups);

  return (
    <Button
      variant="outlined"
      onClick={async () => {
        if (spreadsheetReportsDemo) {
          const { data }: any = await HTTPService.request({
            apiUrlKey: 'reportsApiUrl',
            method: 'get',
            relativePath: `/v1/reports/single-entity/uuid/summary/2020-12-12/USD?targetContainerId=${String(
              WorldGroupUuid
            )}`
          });

          const report: any = await HTTPService.simpleRequest({
            method: 'get',
            url: data.url,
            responseType: 'blob'
          });

          saveAs(report, `2022 Tax Provision - Interco Inc Reporting Package.xlsx`);
          return;
        }

        const response: any = await HTTPService.request({
          apiUrlKey: 'excelReports',
          method: 'get',
          relativePath: `/v1/workbook/${reportType}/${reportSourceId}/${currencyISO}`
        });

        const reportSourceName =
          entities[reportSourceId]?.name ??
          customGroups.find(({ groupId }) => groupId === reportSourceId)?.name ??
          defaultGroupNames[reportSourceId] ??
          '';

        saveAs(
          b64toBlob(response),
          `${String(container?.taxYear)} - ${String(container?.containerName)} - Reports (${reportSourceName}).xlsx`
        );
      }}
    >
      {t('Download Reports')}
    </Button>
  );
};

export default DownloadReportsButton;
