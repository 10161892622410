import React, { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { SlideOut } from '@xbs/xbs-common-ui';

import { useStyles } from './EntityTable.styles';
import { getColumns, isValidEntityName, isValidEntityNumber } from './utils';

import { EditEntityJurisdiction, EntityJurisdiction } from '..';
import { useContainers } from '../../../../hooks';
import { Currency, EntityWithJurisdictionName, EntityWithRates, Jurisdiction, RateUpdate } from '../../../../models';
import {
  deleteEntity,
  updateEntityCurrency,
  updateEntityJurisdiction,
  updateEntityName,
  updateEntityNumber
} from '../../../../redux/entities';
import { selectEntitiesIsLoading, selectJurisdictionsList } from '../../../../selectors';
import { findJurisdictionName } from '../../../../utils';
import LoadingWrapper from '../../../LoadingWrapper';
import Table from '../../../Table';
import { FailedCells, Row } from '../../../Table/Table.proptype';

type Props = {
  entities: EntityWithRates[];
  jurisdictions: Jurisdiction[];
  currencyById: Record<string, Currency>;
  failedCells: FailedCells;
  onRateChange: (rateUpdate: RateUpdate) => void;
  onParentEntityChange: (entityId: EntityWithRates['id']) => void;
};

const EntityTable = ({
  entities,
  jurisdictions,
  currencyById,
  failedCells,
  onRateChange,
  onParentEntityChange
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentContainer } = useContainers();
  const [expandedEntity, setExpandedEntity] = useState<string | null>();
  const [isEditingExpandedEntity, setIsEditingExpandedEntity] = useState<boolean>(false);
  const [isValidEntityNameInput, setIsValidEntityNameInput] = useState<Record<string, boolean>>({});
  const [isValidEntityNumberInput, setIsValidEntityNumberInput] = useState<Record<string, boolean>>({});
  const allJurisdictions = useSelector(selectJurisdictionsList);

  const userHasJurisdictions = Object.entries(allJurisdictions).length > 0;
  const currenciesHaveLoaded = Object.entries(currencyById).length > 0;
  const isLoading = useSelector(selectEntitiesIsLoading) || !userHasJurisdictions || !currenciesHaveLoaded;

  const entitiesWithJurisdictionName = useMemo(() => {
    if (!Array.isArray(entities)) {
      return [];
    }

    const result: Row[] = Array.from({ length: entities.length });
    let i = 0;
    for (const entity of entities) {
      result[i++] = {
        ...entity,
        jurisdiction: findJurisdictionName(jurisdictions, entity.jurisdictionId)
      };
    }

    return (result as unknown) as Array<EntityWithJurisdictionName & EntityWithRates>;
  }, [entities, jurisdictions]);

  const handleEntityNameChange = useCallback(
    (entityId: string, existingValue: string, newValue: string) => {
      const isValid = isValidEntityName(newValue, existingValue, entities);
      setIsValidEntityNameInput((prevIsValidEntityNameInput) => ({
        ...prevIsValidEntityNameInput,
        [entityId]: isValid
      }));
    },
    [entities]
  );

  const handleEntityNumberChange = useCallback(
    (entityId: string, existingValue: string, newValue: string) => {
      const isValid = isValidEntityNumber(newValue, existingValue, entities);
      setIsValidEntityNumberInput((prevIsValidEntityNumberInput) => ({
        ...prevIsValidEntityNumberInput,
        [entityId]: isValid
      }));
    },
    [entities]
  );

  const handleEntityNameBlur = useCallback(
    (entityId: string, existingValue: string, newValue: string) => {
      if (newValue === existingValue) {
        return;
      }

      if (isValidEntityNameInput[entityId]) {
        dispatch(updateEntityName({ entityId, entityName: newValue }));
      } else {
        setIsValidEntityNameInput((prevIsValidEntityNameInput) => ({
          ...prevIsValidEntityNameInput,
          [entityId]: true
        }));
      }
    },
    [dispatch, isValidEntityNameInput]
  );

  const handleEntityNumberBlur = useCallback(
    (entityId: string, existingValue: string, newValue: string) => {
      if (newValue === existingValue) {
        return;
      }

      if (isValidEntityNumberInput[entityId]) {
        dispatch(updateEntityNumber({ entityId, entityNumber: newValue }));
      } else {
        setIsValidEntityNumberInput((prevIsValidEntityNumberInput) => ({
          ...prevIsValidEntityNumberInput,
          [entityId]: true
        }));
      }
    },
    [dispatch, isValidEntityNumberInput]
  );

  const handleEntityCurrencyUpdate = useCallback(
    (entityId: string, existingValue: number, newValue: number) => {
      if (newValue === existingValue) {
        return;
      }

      dispatch(updateEntityCurrency({ entityId, currencyId: newValue }));
    },
    [dispatch]
  );

  const handleEntityJurisdictionUpdate = useCallback(
    (entityId: string, existingValue: string, newValue: string) => {
      if (String(newValue) === String(existingValue)) {
        return;
      }

      dispatch(updateEntityJurisdiction({ entityId, jurisdictionId: newValue }));
    },
    [dispatch]
  );

  const handleDeleteEntity = useCallback(
    (entityId: string) => {
      dispatch(deleteEntity({ entityId }));
    },
    [dispatch]
  );

  const selectedEntity = entitiesWithJurisdictionName?.find((entity) => entity.entityId === expandedEntity);
  const jurisdictionsById = Object.fromEntries(
    allJurisdictions.map((jurisdiction) => [jurisdiction.jurisdictionId, jurisdiction])
  );

  const columns = useMemo(
    () =>
      getColumns({
        t,
        classes,
        isValidEntityNameInput,
        isValidEntityNumberInput,
        failedCells,
        handleEntityNameChange,
        handleEntityNameBlur,
        handleEntityNumberChange,
        handleEntityNumberBlur,
        handleEntityCurrencyUpdate,
        handleEntityJurisdictionUpdate,
        onParentEntityChange,
        setIsEditingExpandedEntity,
        setExpandedEntity,
        currencyById,
        jurisdictionsById,
        handleDeleteEntity,
        isContainerFinalized: Boolean(currentContainer?.isFinalized)
      }),
    [
      t,
      classes,
      isValidEntityNameInput,
      isValidEntityNumberInput,
      failedCells,
      handleEntityNameChange,
      handleEntityNameBlur,
      handleEntityNumberChange,
      handleEntityNumberBlur,
      handleEntityCurrencyUpdate,
      handleEntityJurisdictionUpdate,
      onParentEntityChange,
      setIsEditingExpandedEntity,
      setExpandedEntity,
      currencyById,
      jurisdictionsById,
      handleDeleteEntity,
      currentContainer?.isFinalized
    ]
  );

  return (
    <LoadingWrapper isLoading={isLoading}>
      <>
        <Table
          className={classes.table}
          rows={entitiesWithJurisdictionName}
          columns={columns}
          selected={selectedEntity ? [selectedEntity] : []}
          noRowLabel={t('No Entities')}
          isNotEditableShaded={false}
        />
        <SlideOut.Drawer
          title={(selectedEntity as any)?.name}
          subtitle={t(isEditingExpandedEntity ? 'Edit State Filing Jurisdictions' : 'Filing Jurisdictions')}
          titleHeight={94.5}
          isOpen={Boolean(selectedEntity)}
          isFloating={false}
          isDividerHidden={isEditingExpandedEntity}
          onClose={() => {
            setExpandedEntity(null);
            setIsEditingExpandedEntity(false);
          }}
        >
          <>
            {isEditingExpandedEntity && <Box className={classes.drawerHeader}>{t('Editing Entity')}</Box>}
            <Box className={classes.drawerContainer}>
              {selectedEntity &&
                (isEditingExpandedEntity ? (
                  <EditEntityJurisdiction
                    entity={selectedEntity}
                    setIsEditingExpandedEntity={setIsEditingExpandedEntity}
                  />
                ) : (
                  <EntityJurisdiction
                    entity={selectedEntity}
                    jurisdictions={jurisdictions}
                    failedCells={failedCells}
                    setIsEditingExpandedEntity={setIsEditingExpandedEntity}
                    onRateChange={onRateChange}
                  />
                ))}
            </Box>
          </>
        </SlideOut.Drawer>
      </>
    </LoadingWrapper>
  );
};

export default EntityTable;
